export default {
    zh:{
        taskCard:'定时任务',
        task:{
            operate:'操作',
            execute:'立即执行',
            start:'启动',
            stop:'停止',
            edit:'编辑',
            delete:'删除',
            executeNowTip:'确定立刻执行定时任务吗？',
            selRowTip:'请选择要立刻执行的任务',
            selWrongTip:'请全部选择已启动的任务进行该操作！',
            executeSureTip:'确定要执行该操作吗？',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
                status:'状态',
                status_placeholder:'请选择状态...',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_CRON:'cron表达式',
                F_STATUS:'状态',
                F_TASK_KEY:'任务Key',
                F_EXCUTE_NUM:'总执行次数',
                F_LIMIT_EXCUTE_NUM:'最多执行次数',
                F_DESC:'描述',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule:'请输入名称',
                taskKey:'定时任务Key',
                taskKey_rule:'请选择定时任务Key',
                cron:'cron表达式',
                cron_placeholder:'请输入cron表达式',
                cron_rule:'请输入cron表达式',
                status:'状态',
                excuteNum:'累计执行次数',
                limitExcuteNum:'最多执行次数',
                limitExcuteNum_tip:'-1表示不限制次数;超限后只能手动执行',
                desc:'描述',
                desc_placeholder:'请输入说明',
            },
            taskParams:{
                F_NAME:'参数名',
                F_NAME_placeholder:'请输入参数名',
                F_VALUE:'参数值',
                F_VALUE_placeholder:'请输入参数值',
            }
        }
    },
    en:{
        taskCard:'Scheduled Tasks',
        operate:'operate',
        execute:'execute',
        start:'start',
        stop:'stop',
        edit:'edit',
        delete:'delete',
        executeNowTip:'Are you sure to execute the scheduled task immediately？',
        selRowTip:'Please select a task to execute immediately',
        selWrongTip:'Please select all started tasks for this operation！',
        executeSureTip:'Are you sure you want to perform this operation？',
        task:{
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
                status:'status',
                status_placeholder:'please select status...',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_CRON:'cron expression',
                F_STATUS:'status',
                F_TASK_KEY:'task Key',
                F_EXCUTE_NUM:'Total number of executions',
                F_LIMIT_EXCUTE_NUM:'Maximum number of executions',
                F_DESC:'describe',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',
                name_rule:'Please enter a name',
                taskKey:'task Key',
                taskKey_rule:'please select task Key',
                cron:'cron expression',
                cron_placeholder:'please input cron expression',
                cron_rule:'please input cron expression',
                status:'status',
                excuteNum:'execution times',
                limitExcuteNum:'Maximum execution times',
                limitExcuteNum_tip:'-1 Indicates unlimited number of times; Only manual execution is allowed after exceeding the limit',
                desc:'description',
                desc_placeholder:'Please enter a description',
            },
            taskParams:{
                F_NAME:'Param Name',
                F_NAME_placeholder:'please input param name',
                F_VALUE:'Param Value',
                F_VALUE_placeholder:'please input param value',
            }
        }
    }
}